// @flow

import { findKey, isString } from 'lodash-es'

import api from '../core/api'

import { USERS_ACCESS_DENY } from '../components/Files/Files.constants'

const DEFAULT_THUMBNAIL_SIZE = '170X170'

const FILE_CHECK_DELAY_MS = 1000

export const getFileExtension = ({ name }: Object = {}): string => {
  const re = /^.+\.([0-9a-z]+)$/gi
  const match = re.exec(name)

  return match ? match[1] : 'other'
}

export const getFileTypeForExtension = (extension: string): string | void =>
  findKey(TYPE_ALIASES, value => value.find(val => val.includes(extension)))

export const getImageDimensions = async (
  file: Object
): Promise<{ height: number, width: number }> => {
  const { width, height } = await getImageData(file)

  return { width, height }
}

export const isPdfFile = (data: Object = {}): boolean =>
  PDF_TYPES.includes(data.mime_type || data.type)

export const isDocFile = (data: Object = {}): boolean =>
  DOC_TYPES.includes(data.mime_type || data.type)

export const isXlsFile = (data: Object = {}): boolean =>
  XLS_TYPES.includes(data.mime_type || data.type)

export const isXmlFile = (data: Object = {}): boolean =>
  XML_TYPES.includes(data.mime_type || data.type)

export const isImageFile = (data: Object = {}): boolean =>
  IMAGE_TYPES.includes(data.mime_type || data.type)

export const isVideoFile = (data: Object = {}): boolean =>
  VIDEO_TYPES.includes(data.mime_type || data.type)

export const isMediaFile = (data: Object = {}): boolean =>
  isImageFile(data) || isVideoFile(data)

export async function uploadFile(file: Object, outbound?: string) {
  let fileData = null
  let metaData = null

  if (!outbound) {
    const params = {
      name: file.name,
      parent: file.parent,
      size: file.origin[0].size,
      mime_type: file.origin[0].type,
      height: file.origin[0].height,
      width: file.origin[0].width,
      user_access: USERS_ACCESS_DENY,
    }

    metaData = await api.file.getSignedLink(params)
    const { id, signed_link } = metaData

    if (id && signed_link) {
      await api.file.sendMetaData(signed_link, file)
      await api.file.callbackUpload(id)
    }
  } else {
    metaData = api.file.createFile({ ...file, outbound })
  }

  if (metaData.id) {
    fileData = new Promise(res =>
      setTimeout(() => {
        api.file.getFile(metaData.id, { outbound }).then(data => res(data))
      }, FILE_CHECK_DELAY_MS)
    )
  }

  return fileData
}

export const removeFile = (id: number, outbound?: string) => {
  if (!id) {
    return
  }

  try {
    api.file.removeFile(id, outbound)
  } catch (err) {
    console.error(err)
  }
}

const getImageData = async file => {
  const fr = new window.FileReader()

  return new Promise(resolve => {
    fr.readAsDataURL(file)
    fr.onload = () => {
      const src = fr.result

      if (src === 'data:') {
        resolve({ width: 0, height: 0 })

        return
      }

      const image = new window.Image()

      image.onload = () => {
        resolve(image)
      }

      image.onerror = () => {
        resolve(image)
      }

      image.src = src
    }
  })
}

export const convertBlobToBase64 = (blob: Blob): Promise<Object> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = reject
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.readAsDataURL(blob)
  })

export const formatFilesToBase64 = async (
  files: Array<Object>
): Promise<Object> => {
  const pArray = files.map(async f => {
    const base64File = await convertBlobToBase64(f)

    return {
      origin: base64File,
      filename: f.name,
    }
  })
  const processed = await Promise.all(pArray)

  return processed
}

const IMAGE_TYPES = [
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/pjpeg',
  'image/jpg',
  'image/x-windows-bmp',
  'image/x-icon',
]
const PDF_TYPES = ['application/pdf']
const VIDEO_TYPES = ['video/mp4']
const DOC_TYPES = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  'application/vnd.ms-word.document.macroEnabled.12',
  'application/vnd.ms-word.template.macroEnabled.12',
]
const XLS_TYPES = [
  'application/vnd.ms-excel',
  'application/vnd.ms-excel',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.ms-excel.template.macroEnabled.12',
  'application/vnd.ms-excel.addin.macroEnabled.12',
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
]
const PPT_TYPES = [
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.presentationml.template',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  'application/vnd.ms-powerpoint.addin.macroEnabled.12',
  'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
  'application/vnd.ms-powerpoint.template.macroEnabled.12',
  'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
]

const XML_TYPES = ['application/xml', 'text/xml', 'text/plain']

export const DOC_TYPE = 'doc'
export const PDF_TYPE = 'pdf'
export const IMAGE_TYPE = 'img'
export const PPT_TYPE = 'ppt'
export const XLS_TYPE = 'xls'
export const VIDEO_TYPE = 'video'
export const XML_TYPE = 'xml'

const TYPE_ALIASES = {
  [IMAGE_TYPE]: IMAGE_TYPES,
  [VIDEO_TYPE]: VIDEO_TYPES,
  [DOC_TYPE]: DOC_TYPES,
  [PDF_TYPE]: PDF_TYPES,
  [PPT_TYPE]: PPT_TYPES,
  [XLS_TYPE]: XLS_TYPES,
  [XML_TYPE]: XML_TYPES,
}

export const getFileType = (file: Object): string | void =>
  findKey(TYPE_ALIASES, value => value.includes(file.mime_type))

export function getThumbnailSize(size?: ?string): string {
  return size || DEFAULT_THUMBNAIL_SIZE
}

export function getFullThumbnailPath(
  file: Object,
  size?: ?string,
  isVideo?: boolean
): string {
  if (isString(file)) {
    return file
  }

  if (isVideo && file.preview) {
    return file.preview
  }

  const thumbnailSize = getThumbnailSize(size)

  if (file.thumbnails && file.thumbnails[thumbnailSize]) {
    return file.thumbnails[thumbnailSize]
  }

  if (file.thumbnail) {
    return file.thumbnail
  }

  const origin = file.origin || file.avatar

  return origin
}

export function getFileScopeParams({ scope, object, subject }) {
  let params = {}

  if (['mail', 'flat', 'user'].includes(scope)) {
    params.scope = scope

    if (['flat'].includes(scope)) {
      params.object_id = subject?.id
    }

    if (subject?.type === 'profile') {
      params.object_id = subject?.owner
    }
  } else if (
    ['request_thread', 'request_activity', 'request', 'request_chat'].includes(
      scope
    ) ||
    (object && scope === 'tree')
  ) {
    params.scope = 'request'
    params.object_id = object
  }

  if (subject?.type === 'building') {
    params.scope = 'building'
    params.object_id = subject?.id
  }

  return params
}

export function isVideoPlaying(video) {
  return !!(
    video.currentTime > 0 &&
    !video.paused &&
    !video.ended &&
    video.readyState > 2
  )
}
